import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  ApplyButton,
  CheckBoxContainer,
  ClearButton,
  Close,
  FilterWrapper,
  Label,
  SelectContainer,
  Title
} from "./style";
import {Select, SelectRef} from "react-functional-select";
import {database, db} from "../../firebase";
import {Option} from "../../pages/home/Home";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import closeIconWhite from "../../assets/images/close-icon-white.svg";
import { Icon } from "../clinic-card/style";
import {onValue, ref} from "@firebase/database";
import {config} from "../../config";
import {isMobile} from "react-device-detect";
import closeIcon from "../../assets/images/close-icon.svg";
import {Multiselect} from "multiselect-react-dropdown";
import { Adsense } from "@ctrl/react-adsense";

const session = [
  { value: 'single', label: 'Single' },
  { value: 'multi', label: 'Multi' }
]

const skillLevel = [
  { value: 'beginner', label: 'Beginner' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'advanced', label: 'Advanced' }
]

interface FiltersProps {
  setSpotsAvailable: any;
  setSelectedSkillLevel: any;
  setSessionType: any;
  setMinSelectedOption: any;
  onSelectDateChange: any;
  setSelectedCoach: any;
  selectDate: any;
  spotsAvailable: boolean;
  isShowClearButton: boolean;
  isShowFilters: boolean;
  clearFilter: () => void;
  toggleFilters: () => void;
  selectedCity: string;
  isNotFound: boolean;
}


const Filters: React.FC<FiltersProps> = (props) => {
  const {setSpotsAvailable, selectedCity, spotsAvailable, toggleFilters,
    isShowFilters, isShowClearButton, clearFilter, selectDate, onSelectDateChange,
    setSelectedCoach, setSelectedSkillLevel, setSessionType,
    setMinSelectedOption, isNotFound} = props;
  const [coaches, setCoaches] = useState<Array<Option>>([]);
  const selectMinRef = useRef<Multiselect | null>(null);
  const selectMaxRef = useRef<SelectRef | null>(null);
  const selectSessionRef = useRef<SelectRef | null>(null);
  const selectCoachRef = useRef<SelectRef | null>(null);
  const selectSkillRef = useRef<SelectRef | null>(null);
  const [yearOptionsMin, setYearOptionsMin] = useState<Option[] | null>([]);

  const fetchCoaches = async () => {
    const coaches = await db.collection(selectedCity)
      .doc('Hockey')
      .collection('Coaches')
      .get().then(snapshot => snapshot.docs.map(doc => doc.id));

    const coachesOptions: Option[] = coaches.map(i => {
      return {value: i, label: i} as Option;
    })
    setCoaches(coachesOptions);
  }

  useEffect(()=>{
    const starCountRef = ref(database, 'FilterYear');

    onValue(starCountRef, (snapshot: { val: () => any; }) => {
      const data = snapshot.val();
      const range = (start: number, stop: number) => Array.from({ length: stop - start + 1 }, (_, i) => start + i);
      const years: Option[] = range(data.Max, data.Min).map(i => {
        return {
          value: i,
          label: i
        }
      })
      setYearOptionsMin([...years].reverse());
    });
  }, [])

  useEffect(() => {
    fetchCoaches().then();
  },[selectedCity])

  useEffect(() => {
    if (isNotFound) {
      clearFilters();
    }
  }, [isNotFound])

  const getOptionValue = useCallback((opt: Option): number | string => opt.value, []);
  const onSessionTypeChange = useCallback((opt: Option | null): void => setSessionType(opt), []);
  const onCoachChange = useCallback((opt: Option | null): void => setSelectedCoach(opt), []);
  const onSkillLevelChange = useCallback((opt: Option | null): void => setSelectedSkillLevel(opt), []);
  const getOptionLabel = useCallback((opt: Option): string => `${opt.label}`, []);

  const onBirthSelect = (selectedList: [Option]) => {
    setMinSelectedOption([...selectedList]);
  }


  const clearFilters = () => {
    clearFilter();
    selectMinRef.current?.resetSelectedValues();
    selectMaxRef.current?.clearValue();
    selectSessionRef.current?.clearValue();
    selectCoachRef.current?.clearValue();
    selectSkillRef.current?.clearValue();
  }

  return (
    <FilterWrapper className={`${isMobile ? 'mobile-view' : ''} ${isShowFilters ? 'show' : ''}`}>

      {isMobile && <Close src={closeIcon} onClick={toggleFilters}/> }

      <Title className={isMobile ? 'mobile-view' : ''}>Filters</Title>

      <Label>
        Date
        <Flatpickr
          value={selectDate}
          onChange={onSelectDateChange}
          placeholder='Choose a date range'
          options={{
            mode: 'range',
            minDate: new Date(Date.now() - 86400000),
            showMonths: isMobile? 1 : 2,
            disable: [new Date(Date.now() - 86400000)]
          }}
        />
      </Label>

      <Label>
        Birth Years

        <SelectContainer>
          <Multiselect
            className='multiselect'
            ref={selectMinRef}
            options={yearOptionsMin}
            placeholder="Choose birth years"
            displayValue="label"
            avoidHighlightFirstOption={true}
            onSelect={onBirthSelect}
            onRemove={onBirthSelect}
          />
        </SelectContainer>
      </Label>

      <Label>
        Number of sessions

        <SelectContainer>
          <Select
            ref={selectSessionRef}
            isSearchable={false}
            options={session}
            placeholder={'Choose session'}
            themeConfig={config.SELECT_THEME_CONFIG}
            onOptionChange={onSessionTypeChange}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
          />
        </SelectContainer>
      </Label>

      <Label>
        Coach

        <SelectContainer>
          {!!coaches.length && <Select
              ref={selectCoachRef}
              isSearchable={false}
              options={coaches}
              placeholder={'Choose coach'}
              themeConfig={config.SELECT_THEME_CONFIG}
              onOptionChange={onCoachChange}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
          />}
        </SelectContainer>
      </Label>

      <Label>
        Skill Level

        <SelectContainer>
          <Select
            ref={selectSkillRef}
            isSearchable={false}
            options={skillLevel}
            placeholder={'Choose skill level'}
            themeConfig={config.SELECT_THEME_CONFIG}
            onOptionChange={onSkillLevelChange}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
          />
        </SelectContainer>
      </Label>


      <CheckBoxContainer>
        <label htmlFor="spots">
          <input id='spots' checked={spotsAvailable} type="checkbox" onChange={(event) => setSpotsAvailable(event.target.checked)}/>
          Spots available
        </label>

      </CheckBoxContainer>

      {isMobile &&
        <ApplyButton onClick={toggleFilters}>
            Apply
        </ApplyButton>
      }

      {
        isShowClearButton &&
        <ClearButton onClick={clearFilters}>
          <Icon src={closeIconWhite} /> Clear all
        </ClearButton>
      }

      <div className="ads-container">
        <Adsense
          client="ca-pub-8499728628319044"
          slot="9700875670"
        />  
      </div>
      
    </FilterWrapper>
  )
}

export default Filters;
