import React, {useEffect, useState} from "react";
import {Button, ButtonContainer, Icon, LinkExternal, Logo, Wrapper, Top, ScrollDown, MailChimpContent} from "./style";
import mobileLogo from '../../assets/images/logo-mobile.svg';
import desktopLogo from '../../assets/images/new-logo-desktop.svg';
import hamburger from '../../assets/images/hamburger.svg';
import down from '../../assets/images/double_down.svg';
import backgroundImage from '../../assets/images/bg.png';
import backgroundImageMobile from '../../assets/images/bg-mobile.png';
import { isMobile } from "react-device-detect";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {config} from "../../config";
import {MailchimpForm} from "../mailchimp-form/mailchimp-form";

const Header: React.FC = () => {
  const [isShowMobile, setIsShowMobile] = useState<boolean>(false);
  const [isFullHeight, setIsFullHeight] = useState<boolean>(false);

  const showMenu = () => {
    setIsShowMobile(!isShowMobile);
  }

  useEffect(() => {
    const isSubscribed = localStorage.getItem('subscribed');

    if (isSubscribed && +isSubscribed === 1) {
      setIsFullHeight(false)
    } else {
      setIsFullHeight(true)
      // Add auto-close timer
    const timer = setTimeout(() => {
      setIsFullHeight(false);
    }, 2000); // 2000ms = 2 seconds

    // Clean up timer when component unmounts
    return () => clearTimeout(timer);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (event: any) => {
    if (window.scrollY >= 200) {
      setIsFullHeight(false)
    }
  };

  const showSubscribe = () => {
    window.scrollTo(0, 0)
    setIsFullHeight(true)
  }

  const closeHero = () => {
    setIsFullHeight(false)
  }

  return (
    <>
      <Wrapper image={isMobile ? backgroundImageMobile : backgroundImage} fullHeight={isFullHeight} headerHeight={isMobile ? "50" : "80"}>
        <Top headerHeight={isMobile ? "50" : "80"}>
          <Logo src={isMobile ? mobileLogo : desktopLogo} onClick={() => window.location.reload()} />
          {isMobile && <Icon src={hamburger} onClick={showMenu} />}
          <ButtonContainer className={`${isMobile ? 'mobile-view' : ''} ${isShowMobile ? 'show-menu' : ''}`}>
            <LinkExternal href={'https://www.putonaclinic.com/About/'} target='_blank'>
              Become a Coach
            </LinkExternal>
            <LinkExternal href={'https://www.putonaclinic.com/About/aboutus.html'} target='_blank'>
              About us
            </LinkExternal>
            <LinkExternal href={'https://apps.apple.com/ca/app/clinic-find-a-coach/id1627311955'} target='_blank'>
              Download the App
            </LinkExternal>
            <Button onClick={() => {
              showSubscribe(); showMenu();
            }} className={isFullHeight ? 'transform' : ''}>
              Subscribe to our Newsletter
            </Button>
          </ButtonContainer>
        </Top>
        {isFullHeight && <>
            <MailChimpContent className={isMobile ? 'mobile-view' : ''}>
                <div className="title">Your home for hockey coaching</div>
                <div className="subtitle">All the best coaches in one spot. Get updates as camps and clinics become available</div>
                <MailchimpSubscribe url={config.MAILCHIMP_URL}
                                    render={({ subscribe, status, message }) => (
                                      <MailchimpForm
                                        closeHero={() => closeHero()}
                                        status={status}
                                        onValidated={formData => subscribe(formData)}
                                      />
                                    )}/>
            </MailChimpContent>

            <ScrollDown className={isMobile ? 'mobile-view' : ''}>
                <span onClick={closeHero}>
                     SCROLL DOWN
                <Icon src={down} />
                </span>
            </ScrollDown>
        </>}
      </Wrapper>
    </>
  )
}

export default Header;
